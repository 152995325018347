<template>
  <div :class="`menu apps-menu ${addClass}`">
    <div class="apps-menu-bgwrap" @click="toggleMenu">
      <div class="apps-menu-container">
        <div class="apps-menu-card">
          <div class="apps-menu-apps">
            <AppsAvailable />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {
      AppsAvailable: () => import("./AppsAvailable.vue"),
    },
    props: {
      email: String,
      picture: String,
      addClass: null,
    },
    methods: {
      toggleMenu: function() {
        const element = document.querySelector(".apps-menu");
        if (element) {
          element.classList.toggle("active");
        }
      },
    },
  };
</script>

<style lang="scss">
  .apps-menu {
    &.active {
      display: block;
    }
    display: none;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &-absolute {
      position: absolute;
    }
    &-card {
      position: relative;
      z-index: 2;
      background-color: $white;
      margin: $mpadding;
      margin-top: $header_height;
      min-width: 300px;
      max-width: calc(100% - #{$mpadding * 2});
      max-height: calc(100vh - 100px);
      border-radius: $mradius;
      overflow: hidden;
      text-align: center;
      float: right;
      border-color: rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 10px #00000052;
      border: 1px solid #ccc;
      overflow-y: auto;
      &-avatar {
        margin-top: $mpadding;
      }
      &-header {
        border-bottom: solid 1px $chicago;
        padding: $mpadding/2 $mpadding/2;
      }
      &-option {
        a {
          display: block;
          padding: $mpadding;
          cursor: pointer;
          &:hover {
            background: $alto;
          }
        }
      }
    }
    &-bgwrap {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }
</style>
